import React, {Component} from "react"
import styles from "./Footer.module.css"

interface Props {}

export class Footer extends Component<Props, {}> {
    public render() {
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <address className={styles.address}></address>
                    <p className={styles.text}>
                        <strong>Keune Academy by 124</strong>
                    </p>
                    <p className={styles.text}>
                        755 Lawrenceville Suwanee Rd Bldg 1300 | Lawrenceville, GA 30043 | School:
                        (470) 235-9985 | Salon: (678) 990-0762
                    </p>
                </div>
            </footer>
        )
    }
}

import React, {Component, KeyboardEvent} from "react"
import styles from "./SignUp.module.css"
import {LoginWrapper} from "components/LoginWrapper"
import {Button} from "pages/sign-in/parts/Button"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {KlassDropdown} from "components/inputs/KlassDropdown"
import {Captcha} from "components/Captcha"
import {observer} from "mobx-react"
import {observable, toJS} from "mobx"
import {Authorisation} from "models/Authorisation"
import {Link} from "react-router-dom"
import {
    CHECK_PASSWORD_OPTIONS,
    findErrors,
    findPasswordErrors
} from "../../lib/functions/findErrors"
import {Model} from "Model"
import {LabelPassword} from "components/inputs/LabelPassword"
import Swal from "sweetalert2"

interface Props {
    auth: Authorisation
    model: Model
}

export interface SignUpUser {
    otp?
    first_name?
    last_name?
    email?
    password?
    confirm_password?
    json_data: {
        preferred_contact_time?: "morning" | "noon" | "evening"
        phone_number?: string
        how_attend?: string
        previously_applied?: "Yes" | "No"
        crm_id?
        rep_code?
        lead_source?
        about_us: string
        about_us_text?: string
    }
    captcha_value?: string
}

const options1 = [
    {label: "Morning", value: "morning"},
    {label: "Noon", value: "noon"},
    {label: "Evening", value: "evening"}
]

const options3 = [
    {label: "Yes", value: "Yes"},
    {label: "No", value: "No"}
]

const hearAboutUs = [
    {value: "youtube", label: "Youtube"},
    {value: "google", label: "Google"},
    {value: "facebook", label: "Facebook"},
    {value: "twitter", label: "Twitter"},
    {value: "instagram", label: "Instagram"},
    {value: "email", label: "Email"},
    {value: "tv", label: "TV"},
    {value: "newspaper", label: "Newspaper"},
    {value: "other", label: "Other"}
]

const mandatory = {
    presence: {allowEmpty: false}
}

const rules = {
    email: {
        email: true
    }
}

const signUpRules = {
    otp: mandatory,
    first_name: {...mandatory, length: {minimum: 1}},
    last_name: {...mandatory, length: {minimum: 1}},
    email: {
        email: true
    },
    password: {
        ...mandatory,
        length: {minimum: 3},
        format: {
            // /^(?=.*[A-Z])(?=(.*[a-z]){3,})(?=.*([!_@.]){1})(?=.*[0-9].*[0-9]).{8,}$/
            // ^password needs to have one uppercase letter three lowercase letters, one special case letter (!_@.), two digits and 8 chars at minimum.
            pattern: /^.{8,}$/,
            flags: "gm",
            message: "^password needs to have at least 8 chars at minimum."
        }
    },
    confirm_password: {
        ...mandatory,
        equality: "password"
    },
    captcha_value: mandatory
}

const nestedRules = {
    preferred_contact_time: mandatory,
    phone_number: mandatory,
    // how_attend: mandatory,
    previously_applied: mandatory,
    about_us: mandatory
}

const customPasswordConfirmRule = {
    password: {
        ...mandatory,
        length: {minimum: 3},
        format: {
            pattern: /^.{8,}$/,
            flags: "gm",
            message: "^password needs to have at least 8 chars at minimum."
        }
    },
    confirm_password: {
        ...mandatory,
        equality: "password"
    }
}

@observer
export class SignUp extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    @observable
    private nestedErrors: {[key: string]: string} = {}

    @observable
    private backendErrors: string | null = ""

    @observable
    private passwordOptions: any = {
        options: CHECK_PASSWORD_OPTIONS.default
    }

    @observable
    isVisible: boolean = false

    @observable
    isVisibleOtp: boolean = false

    @observable
    private user: SignUpUser = {
        otp: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        json_data: {
            phone_number: "",
            crm_id: "",
            rep_code: "",
            about_us: "",
            about_us_text: ""
        },
        captcha_value: ""
    }

    private OTHER_CONSTANT = {
        keyOther: "about_us",
        valueOther: "Other"
    }

    private recaptchaRef: any = React.createRef()

    private onChangeCaptchaText = (id: string, value: string) => {
        this.user[id] = value
    }

    private isValidEmail = () => {
        const {isValid, errors} = findErrors(this.user, rules)
        this.errors = errors
        return isValid
    }

    private isValid = () => {
        const newRules = {
            ...rules,
            ...signUpRules
        }
        const {isValid, errors} = findErrors(this.user, newRules)

        const {isValid: nestedIsValid, errors: nestedErrors} = findErrors(
            this.user.json_data,
            nestedRules
        )
        this.errors = errors
        this.nestedErrors = nestedErrors
        const {isValid: isValidPassword} = this.passwordOptions
        this.isVisible = !isValidPassword
        // console.log("@@@@@@@ isValid SignUp:", {
        //     errors: {
        //         isValid,
        //         errors: toJS(this.errors)
        //     },
        //     nestedErrors: {
        //         nestedIsValid,
        //         errors: toJS(this.nestedErrors)
        //     },
        //     passwordError: toJS(this.passwordOptions)
        // })
        return isValid && nestedIsValid && isValidPassword
    }

    private isValidPassword = () => {
        const {isValid, options} = findPasswordErrors(this.user)
        this.passwordOptions = {
            isValid,
            options
        }
    }

    private onKeyUpPassword = (event: KeyboardEvent<Element>) => {
        this.isValidPassword()
    }

    private isValidConfirmPassword = () => {
        const {isValid, errors} = findErrors(this.user, customPasswordConfirmRule)
        this.errors = errors
    }

    private onKeyUpConfirmPassword = (event: KeyboardEvent<Element>) => {
        this.isValidConfirmPassword()
    }

    public render() {
        const user = this.user
        // const {model} = this.props
        const {
            onChangeCaptchaText,
            backendErrors,
            onKeyUpPassword,
            onKeyUpConfirmPassword,
            isVisible
        } = this
        const {valueOther} = this.OTHER_CONSTANT
        return (
            <LoginWrapper className={styles.wrapper}>
                <div className={styles.root}>
                    <header className={styles.header}>
                        <h2 className={styles.h2}>Create a New Account</h2>
                        <Link to="/sign-in" className={styles.link}>
                            Sign In
                        </Link>
                    </header>
                    <p className={styles.text}>
                        Thank you for your interest in applying to <br /> Keune Academy by 124.
                    </p>
                    <br />
                    <p className={styles.text}>
                        Please complete the following information and use the Sign Up button to
                        begin the application process.
                    </p>
                    <div className={styles.formArea}>
                        <Label
                            text="Email Address"
                            className={styles.inputUser}
                            error={this.errors["email"]}
                            required>
                            <Input
                                onChange={(v) => {
                                    user.email = v
                                }}
                                value={user.email}
                                type="email"
                            />
                        </Label>
                        {this.isVisibleOtp && (
                            <>
                                <Label
                                    text="One time password"
                                    additionaText="(6-Digit Code from email)"
                                    className={styles.inputUser}
                                    error={this.errors["otp"]}
                                    required>
                                    <Input
                                        onChange={(v) => {
                                            this.user.otp = v.trim()
                                        }}
                                        value={this.user.otp}
                                        type="password"
                                    />
                                </Label>

                                <LabelPassword
                                    text="Password"
                                    required
                                    className={styles.inputPass}
                                    isVisible={isVisible}
                                    minChars={8}
                                    options={this.passwordOptions.options}>
                                    <Input
                                        onChange={(v) => {
                                            user.password = v
                                        }}
                                        value={user.password}
                                        type="password"
                                        onKeyUp={onKeyUpPassword}
                                        onFocus={() => (this.isVisible = true)}
                                        onBlur={() =>
                                            (this.isVisible =
                                                this.passwordOptions.options.value !==
                                                    CHECK_PASSWORD_OPTIONS.values.strong &&
                                                this.passwordOptions.options.value !==
                                                    CHECK_PASSWORD_OPTIONS.values.medium)
                                        }
                                    />
                                </LabelPassword>

                                <Label
                                    text="Confirm Password"
                                    required
                                    className={styles.inputPass}
                                    error={this.errors["confirm_password"]}>
                                    <Input
                                        onChange={(v) => {
                                            user.confirm_password = v
                                        }}
                                        value={user.confirm_password}
                                        type="password"
                                        onKeyUp={onKeyUpConfirmPassword}
                                    />
                                </Label>

                                <Label
                                    text="First Name"
                                    required
                                    className={styles.inputPass}
                                    error={this.errors["first_name"]}>
                                    <Input
                                        onChange={(v) => {
                                            user.first_name = v
                                        }}
                                        value={user.first_name}
                                    />
                                </Label>

                                <Label
                                    text="Last Name"
                                    required
                                    className={styles.inputPass}
                                    error={this.errors["last_name"]}>
                                    <Input
                                        onChange={(v) => {
                                            user.last_name = v
                                        }}
                                        value={user.last_name}
                                    />
                                </Label>

                                <Label
                                    text="Preferred Contact Time"
                                    required
                                    error={this.nestedErrors["preferred_contact_time"]}>
                                    <KlassDropdown
                                        onChange={(v) => {
                                            user.json_data.preferred_contact_time = v.value
                                        }}
                                        options={options1}
                                        value={options1.find((el) => {
                                            return (
                                                el.value === user.json_data.preferred_contact_time
                                            )
                                        })}
                                    />
                                </Label>

                                <Label
                                    text="Phone Number"
                                    required
                                    className={styles.inputPass}
                                    error={this.nestedErrors["phone_number"]}>
                                    <Input
                                        onChange={(v) => {
                                            user.json_data.phone_number = v
                                        }}
                                        value={user.json_data.phone_number}
                                    />
                                </Label>

                                {/* <Label
                            text="How would you like to attend?"
                            required
                            error={this.nestedErrors["how_attend"]}>
                            <KlassDropdown
                                onChange={(v) => {
                                    user.json_data.how_attend = v.value
                                }}
                                options={options2}
                                value={options2.find((el) => {
                                    return el.value === user.json_data.how_attend
                                })}
                            />
                        </Label> */}

                                <Label
                                    text="How did you hear about us?"
                                    required
                                    error={this.nestedErrors["about_us"]}>
                                    <KlassDropdown
                                        onChange={(v) => {
                                            user.json_data.about_us = v.value
                                        }}
                                        options={hearAboutUs}
                                        value={hearAboutUs.find((el) => {
                                            return el.value === user.json_data.about_us
                                        })}
                                    />
                                </Label>

                                {user.json_data.about_us === valueOther && (
                                    <Label
                                        text="Describe how did you hear about us / Referal name"
                                        className={styles.inputPass}>
                                        <Input
                                            onChange={(v) => {
                                                user.json_data.about_us_text = v
                                            }}
                                            value={user.json_data.about_us_text}
                                        />
                                    </Label>
                                )}

                                <Label
                                    text="Have you previously applied to Keune?"
                                    required
                                    error={this.nestedErrors["previously_applied"]}>
                                    <KlassDropdown
                                        onChange={(v) => {
                                            user.json_data.previously_applied = v.value
                                        }}
                                        options={options3}
                                        value={options3.find((el) => {
                                            return el.value === user.json_data.previously_applied
                                        })}
                                    />
                                </Label>

                                <Captcha
                                    recaptchaRef={this.recaptchaRef}
                                    id={`captcha_value`}
                                    user={this.user}
                                    onChange={onChangeCaptchaText}
                                    errors={this.errors}
                                    backendErrors={backendErrors}
                                />
                            </>
                        )}
                    </div>
                    {this.isVisibleOtp && (
                        <>
                            <p className={styles.textUnderForm}>
                                Please make a note of your email address and password as they will
                                be required should you wish to make application changes in the
                                future.
                            </p>
                            <p className={styles.tcpaText}>
                                By submitting this form, you are giving your express written consent
                                for <strong>Keune Academy by 124</strong> to contact you regarding
                                our programs and services using email, sms, telephone or text -
                                including our use of automated technology for calls and periodic
                                texts to any wireless number you provide. Message and data rates may
                                apply. <br /> This consent is not required to purchase
                                goods/services and you may always call us directly at{" "}
                                <strong>(470) 235-9985, (678) 990-0762</strong>
                            </p>
                        </>
                    )}

                    <Button
                        text="SIGN UP"
                        onClick={() => {
                            if (!this.isVisibleOtp) {
                                this.sendOneTimePasswordEmailToUser()
                            } else {
                                this.signUp()
                            }
                        }}
                        className={styles.button}
                    />
                </div>
            </LoginWrapper>
        )
    }

    private async sendOneTimePasswordEmailToUser() {
        if (this.isValidEmail()) {
            const {email} = this.user
            const response = await this.props.model.sendCreateAccountOtpEmail(email)
            // alert(`${response.message}`)
            Swal.fire({
                icon: response.success ? "success" : "warning",
                text: `${response.message}`
            })
            if (response.success) {
                this.isVisibleOtp = true
            }
        }
    }

    private async signUp() {
        this.backendErrors = ""
        const CAPTCHA_WORD = "Captcha"
        if (this.recaptchaRef.current) {
            const captchaToken = await this.recaptchaRef.current.executeAsync()
            console.log("@@@ signUp captch_value", captchaToken)
            this.user.captcha_value = captchaToken
            this.recaptchaRef.current.reset()
        }
        if (this.isValid()) {
            this.user.json_data.lead_source = localStorage.getItem("lead_source")
            const response = await this.props.auth.signUp("keuneacademy", {...this.user})
            if (this.recaptchaRef.current) {
                this.recaptchaRef.current.reset()
                this.user.captcha_value = ""
            }
            if (!response.success) {
                const {
                    errors: [{message}]
                } = response
                if (message.includes(CAPTCHA_WORD)) {
                    this.backendErrors = message
                }
            }
        }
    }
}
